/* global document, Intl */
(function () {
  'use strict';

  class FormDuoRoutesCtrl {
    constructor(safetyService, ServiceVfiltro, FactoryVfiltro, Authenticator, $window, $document, $scope, $log, blockUI, $stateParams, $state) {
      this.authenticator = Authenticator;
      this.safetyService = safetyService;
      this.factoryVfiltro = FactoryVfiltro;
      this.serviceVfiltro = ServiceVfiltro;
      this.stateParams = $stateParams;
      this.window = $window;
      this.document = $document[0];
      this.log = $log;
      this.scope = $scope;
      this.blockUI = blockUI;
      this.state = $state;

      this.reactAppName = 'safetyApp';
      this.reactPageComponentName = './DuoRouteFormPageRoute';
      this.reactComponentContainerId = 'safety-duo-route-form';
      this.reactComponentContainer = this.document.getElementById(this.reactComponentContainerId);
      this.reactPageComponentType = null;
      this.reactRoot = null;

      this.duoRouteUUID = this.stateParams.duoRouteUUID;
      this.user = null;

      this.initialize();

      this.scope.$on('$destroy', () => this.unmountReactComponent());
    }

    initialize() {
      this.blockUI.start();
      return this.initFilter()
        .then(() => this.loadUser())
        .then(() => this.loadReact())
        .then(() => this.renderReactComponent())
        .finally(() => {
          this.blockUI.reset();
          this.blockUI.stop();
        });
    }

    initFilter() {
      return this.serviceVfiltro.init(
        this.factoryVfiltro.get([])
      );
    }

    loadUser() {
      return this.authenticator.getUser()
        .then((user) => {
          this.user = user;
        });
    }

    loadReact() {
      return this.safetyService
        .initialize()
        .then(() => this.safetyService.getComponent(this.reactAppName, this.reactPageComponentName))
        .then((page) => {
          this.reactPageComponentType = page().default;
        });
    }

    renderReactComponent() {
      const props = this.getReactProps();
      this.reactRoot = this.window.ReactDOM.createRoot(this.reactComponentContainer);
      const component = this.window.React.createElement(this.reactPageComponentType, props);
      return this.reactRoot.render(component);
    }

    unmountReactComponent() {
      if (this.reactRoot) {
        this.reactRoot.unmount();
        this.log.log('React component unmounted');
      } else {
        this.log.log('Container not found or React component was not mounted.');
      }
    }

    goBackToList() {
      this.state.go('root.duo-routes-cadastro');
    }

    getReactProps() {
      return {
        user: this.user,
        duoRouteUUID: this.duoRouteUUID,
        goBackToList: () => this.goBackToList(),
      };
    }

  }

  angular
    .module('duo-routes.cadastro-rotas')
    .controller('FormDuoRoutesCtrl', FormDuoRoutesCtrl);
}());
