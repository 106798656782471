(function () {
  'use strict';
  const ATIVO = true, INATIVO = false;

  angular
    .module('FeatureFlagModule')
    .constant('FeatureFlag', {
      /**
       * Ativa ou inativa verificação de soluções.
       * @type {boolean}
       */
      SOLUCOES: ATIVO,
      SOLUCOES_AVISO: INATIVO,
      CONFIG_NOTIFICACAO_OCORRENCIA: ATIVO,
      SNAPSHOTS_HIST_DETALHADO: ATIVO,
      DOCUMENTOS_MOTORISTA: ATIVO,
      SNACKBAR_PRODUCT_VALIDATION: INATIVO,
      ADVISOR: ATIVO,
      VIDEO_LIBRARY: ATIVO,
      TUTORIAL_BUSCA_REGIAO: INATIVO,
      DIALOG_PONTO_REESTRUTURADO: ATIVO,
      VIGENCIA_PROGRAMADA: ATIVO,
      MENU_INSIGHTS: ATIVO,
      SOLUCAO_REC_FACIAL: ATIVO,
      EXPORTAR_PDF_HISTORICO_OCORRENCIAS: ATIVO,
      SHOW_EVENT_DIALOG: ATIVO,
      SHOW_HARSH_EVENTS_RISK_REGION: ATIVO,
      SHOW_ULTRAPASSAGEM_ILEGAL: ATIVO
    })
    .constant('FeatureHubConfigKeys', {
      ENABLE_PLAYBACK_CONTENT: 'TTLAV777_Playback_content',
      ENABLE_HIPERLINK_DRIVER_RECORD: 'TTLAINT1314_open_link_driver_record',
      ENABLE_6_CAMERAS: 'TTLAV1145_videos_with_6_cameras',
      ENABLE_FEAT_ROLE_PRONTUARIO_VISUALIZAR: 'TTLAINT1512_role_PRONTUARIO_MOTORISTA_VISUALIZAR',
      FEATURE_VSAFE_BOLETIM: 'TTLATEL1853_vsafe_boletim',
      FEATURE_PREMIUM_TELEMETRY: 'TTLATEL1722_premium_telemetry',
      DECORATED_VIDEO_PLAYER: 'STFM_974_DISPLAY_DECORATED_VIDEO_PLAYER_V3',
      DEVICE_HEALTH_PAGE: 'STFM-1371-DeviceHealth',
      ENABLE_INTEGRATION_CODE_LANDMARK: 'TTLATEL1891_integration_code_landmark',
      COLLAPSIBLE_TABLES: 'STFM1206_collapsible_tables',
      FEATURE_THUMBNAILS_BUTTON: 'STFM1263_thumbnails_buttons',
      SEPARATE_STABILITY_CONTROL: 'STFM-1217_separate_stability_control',
      ENABLE_RETENTION_PERIOD: 'STFM1332_retention_period_configuration',
      FIX_UI_BEHAVIOR: 'STFM1392_fix_ui_behavior',
      ENABLE_SCREEN_ACCESS_CHECK_BUNDLE: 'TTLATEL1724_SCREEN_BUNDLE_PERMISSION',
      ENABLE_DRIVER_LINK: 'TTLAINT1555_driver_link'
    });
}());
