/* global document, Intl */
(function () {
  'use strict';

  class CadastroDuoRoutesCtrl {
    constructor(Keys, ServiceVfiltro, FactoryVfiltro, safetyService, Authenticator, $window, $document, $scope, $log, blockUI, $state) {
      this.keys = Keys;
      this.factoryVfiltro = FactoryVfiltro;
      this.serviceVfiltro = ServiceVfiltro;
      this.authenticator = Authenticator;
      this.safetyService = safetyService;
      this.window = $window;
      this.document = $document[0];
      this.log = $log;
      this.scope = $scope;
      this.blockUI = blockUI;
      this.state = $state;

      this.reactAppName = 'safetyApp';
      this.reactPageComponentName = './DuoRouteListPageRoute';
      this.reactComponentContainerId = 'safety-duo-route-list';
      this.reactComponentContainer = this.document.getElementById(this.reactComponentContainerId);
      this.reactPageComponentType = null;
      this.reactRoot = null;

      this.filtro = null;
      this.user = null;

      this.initialize();

      this.scope.$on('$destroy', () => this.unmountReactComponent());
    }

    initialize() {
      this.blockUI.start();
      return this.initFilter()
        .then(() => this.loadUser())
        .then(() => this.loadReact())
        .then(() => this.renderReactComponent())
        .finally(() => {
          this.blockUI.reset();
          this.blockUI.stop();
        });
    }

    initFilter() {
      return this.serviceVfiltro.init(
        this.factoryVfiltro.get([
          {
            name: 'uoId',
            key: this.keys.uo.name
          }
        ])
      ).then((filtro) => {
        this.filtro = filtro;
      });
    }

    loadUser() {
      return this.authenticator.getUser()
        .then((user) => {
          this.user = user;
        });
    }

    loadReact() {
      return this.safetyService
        .initialize()
        .then(() => this.safetyService.getComponent(this.reactAppName, this.reactPageComponentName))
        .then((page) => {
          this.reactPageComponentType = page().default;
        });
    }

    renderReactComponent() {
      const props = this.getReactProps();
      this.reactRoot = this.window.ReactDOM.createRoot(this.reactComponentContainer);
      const component = this.window.React.createElement(this.reactPageComponentType, props);
      return this.reactRoot.render(component);
    }

    unmountReactComponent() {
      if (this.reactRoot) {
        this.reactRoot.unmount();
        this.log.log('React component unmounted');
      } else {
        this.log.log('Container not found or React component was not mounted.');
      }
    }

    goToRouteForm(duoRouteUUID) {
      this.state.go('root.duo-routes-cadastro.form', {
        duoRouteUUID: duoRouteUUID
      });
    }

    getReactProps() {
      return {
        user: this.user,
        filter: {
          ouId: this.filtro.find((f) => f.key === this.keys.uo.name).value.id
        },
        goToRouteForm: (duoRouteUUID) => this.goToRouteForm(duoRouteUUID),
      };
    }

  }

  angular
    .module('duo-routes.cadastro-rotas')
    .controller('CadastroDuoRoutesCtrl', CadastroDuoRoutesCtrl);
}());
